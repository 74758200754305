import { default as _91slug_93S0js3RtOm6Meta } from "/src/pages/[slug].vue?macro=true";
import { default as archiveJQ4CpvPGeKMeta } from "/src/pages/archive.vue?macro=true";
import { default as editionsGQbQ00dHgwMeta } from "/src/pages/editions.vue?macro=true";
import { default as _91slug_93XZxplWq9fsMeta } from "/src/pages/education/[slug].vue?macro=true";
import { default as educationqwIUyLuOoXMeta } from "/src/pages/education.vue?macro=true";
import { default as _91slug_93bk8t2N9m7hMeta } from "/src/pages/essays/[slug].vue?macro=true";
import { default as indexLFG4fJZqYmMeta } from "/src/pages/essays/index.vue?macro=true";
import { default as _91slug_9343zyO1f1dnMeta } from "/src/pages/exhibitions/[slug].vue?macro=true";
import { default as index14QJMbCKgUMeta } from "/src/pages/exhibitions/index.vue?macro=true";
import { default as indexvsa0KwJWqiMeta } from "/src/pages/index.vue?macro=true";
import { default as _91slug_93AeV9X0KaYnMeta } from "/src/pages/news/[slug].vue?macro=true";
import { default as newsizWrFoo21yMeta } from "/src/pages/news.vue?macro=true";
import { default as _91slug_937aVlzGJuI2Meta } from "/src/pages/online-exhibitions/[slug].vue?macro=true";
import { default as indexLM5xAtWmBNMeta } from "/src/pages/online-exhibitions/index.vue?macro=true";
import { default as page_45previewqdX5u0GUuHMeta } from "/src/node_modules/@brambergvd/nuxt-base-layer/pages/page-preview.vue?macro=true";
import { default as request_45for_45photographersjgWGzBuZPWMeta } from "/src/pages/request-for-photographers.vue?macro=true";
import { default as _91slug_93tZaihNngU0Meta } from "/src/pages/talks/[slug].vue?macro=true";
import { default as talkse8nSpHPxjHMeta } from "/src/pages/talks.vue?macro=true";
import { default as visitqlkmWMbccyMeta } from "/src/pages/visit.vue?macro=true";
export default [
  {
    name: _91slug_93S0js3RtOm6Meta?.name ?? "slug___en",
    path: _91slug_93S0js3RtOm6Meta?.path ?? "/:slug()",
    meta: _91slug_93S0js3RtOm6Meta || {},
    alias: _91slug_93S0js3RtOm6Meta?.alias || [],
    redirect: _91slug_93S0js3RtOm6Meta?.redirect,
    component: () => import("/src/pages/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93S0js3RtOm6Meta?.name ?? "slug___al",
    path: _91slug_93S0js3RtOm6Meta?.path ?? "/al/:slug()",
    meta: _91slug_93S0js3RtOm6Meta || {},
    alias: _91slug_93S0js3RtOm6Meta?.alias || [],
    redirect: _91slug_93S0js3RtOm6Meta?.redirect,
    component: () => import("/src/pages/[slug].vue").then(m => m.default || m)
  },
  {
    name: archiveJQ4CpvPGeKMeta?.name ?? "archive___en",
    path: archiveJQ4CpvPGeKMeta?.path ?? "/archive",
    meta: archiveJQ4CpvPGeKMeta || {},
    alias: archiveJQ4CpvPGeKMeta?.alias || [],
    redirect: archiveJQ4CpvPGeKMeta?.redirect,
    component: () => import("/src/pages/archive.vue").then(m => m.default || m)
  },
  {
    name: archiveJQ4CpvPGeKMeta?.name ?? "archive___al",
    path: archiveJQ4CpvPGeKMeta?.path ?? "/al/archive",
    meta: archiveJQ4CpvPGeKMeta || {},
    alias: archiveJQ4CpvPGeKMeta?.alias || [],
    redirect: archiveJQ4CpvPGeKMeta?.redirect,
    component: () => import("/src/pages/archive.vue").then(m => m.default || m)
  },
  {
    name: editionsGQbQ00dHgwMeta?.name ?? "editions___en",
    path: editionsGQbQ00dHgwMeta?.path ?? "/editions",
    meta: editionsGQbQ00dHgwMeta || {},
    alias: editionsGQbQ00dHgwMeta?.alias || [],
    redirect: editionsGQbQ00dHgwMeta?.redirect,
    component: () => import("/src/pages/editions.vue").then(m => m.default || m)
  },
  {
    name: editionsGQbQ00dHgwMeta?.name ?? "editions___al",
    path: editionsGQbQ00dHgwMeta?.path ?? "/al/editions",
    meta: editionsGQbQ00dHgwMeta || {},
    alias: editionsGQbQ00dHgwMeta?.alias || [],
    redirect: editionsGQbQ00dHgwMeta?.redirect,
    component: () => import("/src/pages/editions.vue").then(m => m.default || m)
  },
  {
    name: educationqwIUyLuOoXMeta?.name ?? "education___en",
    path: educationqwIUyLuOoXMeta?.path ?? "/education",
    meta: educationqwIUyLuOoXMeta || {},
    alias: educationqwIUyLuOoXMeta?.alias || [],
    redirect: educationqwIUyLuOoXMeta?.redirect,
    component: () => import("/src/pages/education.vue").then(m => m.default || m),
    children: [
  {
    name: _91slug_93XZxplWq9fsMeta?.name ?? "education-slug___en",
    path: _91slug_93XZxplWq9fsMeta?.path ?? ":slug()",
    meta: _91slug_93XZxplWq9fsMeta || {},
    alias: _91slug_93XZxplWq9fsMeta?.alias || [],
    redirect: _91slug_93XZxplWq9fsMeta?.redirect,
    component: () => import("/src/pages/education/[slug].vue").then(m => m.default || m)
  }
]
  },
  {
    name: educationqwIUyLuOoXMeta?.name ?? "education___al",
    path: educationqwIUyLuOoXMeta?.path ?? "/al/education",
    meta: educationqwIUyLuOoXMeta || {},
    alias: educationqwIUyLuOoXMeta?.alias || [],
    redirect: educationqwIUyLuOoXMeta?.redirect,
    component: () => import("/src/pages/education.vue").then(m => m.default || m),
    children: [
  {
    name: _91slug_93XZxplWq9fsMeta?.name ?? "education-slug___al",
    path: _91slug_93XZxplWq9fsMeta?.path ?? ":slug()",
    meta: _91slug_93XZxplWq9fsMeta || {},
    alias: _91slug_93XZxplWq9fsMeta?.alias || [],
    redirect: _91slug_93XZxplWq9fsMeta?.redirect,
    component: () => import("/src/pages/education/[slug].vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91slug_93bk8t2N9m7hMeta?.name ?? "essays-slug___en",
    path: _91slug_93bk8t2N9m7hMeta?.path ?? "/essays/:slug()",
    meta: _91slug_93bk8t2N9m7hMeta || {},
    alias: _91slug_93bk8t2N9m7hMeta?.alias || [],
    redirect: _91slug_93bk8t2N9m7hMeta?.redirect,
    component: () => import("/src/pages/essays/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93bk8t2N9m7hMeta?.name ?? "essays-slug___al",
    path: _91slug_93bk8t2N9m7hMeta?.path ?? "/al/essays/:slug()",
    meta: _91slug_93bk8t2N9m7hMeta || {},
    alias: _91slug_93bk8t2N9m7hMeta?.alias || [],
    redirect: _91slug_93bk8t2N9m7hMeta?.redirect,
    component: () => import("/src/pages/essays/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexLFG4fJZqYmMeta?.name ?? "essays___en",
    path: indexLFG4fJZqYmMeta?.path ?? "/essays",
    meta: indexLFG4fJZqYmMeta || {},
    alias: indexLFG4fJZqYmMeta?.alias || [],
    redirect: indexLFG4fJZqYmMeta?.redirect,
    component: () => import("/src/pages/essays/index.vue").then(m => m.default || m)
  },
  {
    name: indexLFG4fJZqYmMeta?.name ?? "essays___al",
    path: indexLFG4fJZqYmMeta?.path ?? "/al/essays",
    meta: indexLFG4fJZqYmMeta || {},
    alias: indexLFG4fJZqYmMeta?.alias || [],
    redirect: indexLFG4fJZqYmMeta?.redirect,
    component: () => import("/src/pages/essays/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_9343zyO1f1dnMeta?.name ?? "exhibitions-slug___en",
    path: _91slug_9343zyO1f1dnMeta?.path ?? "/exhibitions/:slug()",
    meta: _91slug_9343zyO1f1dnMeta || {},
    alias: _91slug_9343zyO1f1dnMeta?.alias || [],
    redirect: _91slug_9343zyO1f1dnMeta?.redirect,
    component: () => import("/src/pages/exhibitions/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_9343zyO1f1dnMeta?.name ?? "exhibitions-slug___al",
    path: _91slug_9343zyO1f1dnMeta?.path ?? "/al/exhibitions/:slug()",
    meta: _91slug_9343zyO1f1dnMeta || {},
    alias: _91slug_9343zyO1f1dnMeta?.alias || [],
    redirect: _91slug_9343zyO1f1dnMeta?.redirect,
    component: () => import("/src/pages/exhibitions/[slug].vue").then(m => m.default || m)
  },
  {
    name: index14QJMbCKgUMeta?.name ?? "exhibitions___en",
    path: index14QJMbCKgUMeta?.path ?? "/exhibitions",
    meta: index14QJMbCKgUMeta || {},
    alias: index14QJMbCKgUMeta?.alias || [],
    redirect: index14QJMbCKgUMeta?.redirect,
    component: () => import("/src/pages/exhibitions/index.vue").then(m => m.default || m)
  },
  {
    name: index14QJMbCKgUMeta?.name ?? "exhibitions___al",
    path: index14QJMbCKgUMeta?.path ?? "/al/exhibitions",
    meta: index14QJMbCKgUMeta || {},
    alias: index14QJMbCKgUMeta?.alias || [],
    redirect: index14QJMbCKgUMeta?.redirect,
    component: () => import("/src/pages/exhibitions/index.vue").then(m => m.default || m)
  },
  {
    name: indexvsa0KwJWqiMeta?.name ?? "index___en",
    path: indexvsa0KwJWqiMeta?.path ?? "/",
    meta: indexvsa0KwJWqiMeta || {},
    alias: indexvsa0KwJWqiMeta?.alias || [],
    redirect: indexvsa0KwJWqiMeta?.redirect,
    component: () => import("/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexvsa0KwJWqiMeta?.name ?? "index___al",
    path: indexvsa0KwJWqiMeta?.path ?? "/al",
    meta: indexvsa0KwJWqiMeta || {},
    alias: indexvsa0KwJWqiMeta?.alias || [],
    redirect: indexvsa0KwJWqiMeta?.redirect,
    component: () => import("/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: newsizWrFoo21yMeta?.name ?? "news___en",
    path: newsizWrFoo21yMeta?.path ?? "/news",
    meta: newsizWrFoo21yMeta || {},
    alias: newsizWrFoo21yMeta?.alias || [],
    redirect: newsizWrFoo21yMeta?.redirect,
    component: () => import("/src/pages/news.vue").then(m => m.default || m),
    children: [
  {
    name: _91slug_93AeV9X0KaYnMeta?.name ?? "news-slug___en",
    path: _91slug_93AeV9X0KaYnMeta?.path ?? ":slug()",
    meta: _91slug_93AeV9X0KaYnMeta || {},
    alias: _91slug_93AeV9X0KaYnMeta?.alias || [],
    redirect: _91slug_93AeV9X0KaYnMeta?.redirect,
    component: () => import("/src/pages/news/[slug].vue").then(m => m.default || m)
  }
]
  },
  {
    name: newsizWrFoo21yMeta?.name ?? "news___al",
    path: newsizWrFoo21yMeta?.path ?? "/al/news",
    meta: newsizWrFoo21yMeta || {},
    alias: newsizWrFoo21yMeta?.alias || [],
    redirect: newsizWrFoo21yMeta?.redirect,
    component: () => import("/src/pages/news.vue").then(m => m.default || m),
    children: [
  {
    name: _91slug_93AeV9X0KaYnMeta?.name ?? "news-slug___al",
    path: _91slug_93AeV9X0KaYnMeta?.path ?? ":slug()",
    meta: _91slug_93AeV9X0KaYnMeta || {},
    alias: _91slug_93AeV9X0KaYnMeta?.alias || [],
    redirect: _91slug_93AeV9X0KaYnMeta?.redirect,
    component: () => import("/src/pages/news/[slug].vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91slug_937aVlzGJuI2Meta?.name ?? "online-exhibitions-slug___en",
    path: _91slug_937aVlzGJuI2Meta?.path ?? "/online-exhibitions/:slug()",
    meta: _91slug_937aVlzGJuI2Meta || {},
    alias: _91slug_937aVlzGJuI2Meta?.alias || [],
    redirect: _91slug_937aVlzGJuI2Meta?.redirect,
    component: () => import("/src/pages/online-exhibitions/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_937aVlzGJuI2Meta?.name ?? "online-exhibitions-slug___al",
    path: _91slug_937aVlzGJuI2Meta?.path ?? "/al/online-exhibitions/:slug()",
    meta: _91slug_937aVlzGJuI2Meta || {},
    alias: _91slug_937aVlzGJuI2Meta?.alias || [],
    redirect: _91slug_937aVlzGJuI2Meta?.redirect,
    component: () => import("/src/pages/online-exhibitions/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexLM5xAtWmBNMeta?.name ?? "online-exhibitions___en",
    path: indexLM5xAtWmBNMeta?.path ?? "/online-exhibitions",
    meta: indexLM5xAtWmBNMeta || {},
    alias: indexLM5xAtWmBNMeta?.alias || [],
    redirect: indexLM5xAtWmBNMeta?.redirect,
    component: () => import("/src/pages/online-exhibitions/index.vue").then(m => m.default || m)
  },
  {
    name: indexLM5xAtWmBNMeta?.name ?? "online-exhibitions___al",
    path: indexLM5xAtWmBNMeta?.path ?? "/al/online-exhibitions",
    meta: indexLM5xAtWmBNMeta || {},
    alias: indexLM5xAtWmBNMeta?.alias || [],
    redirect: indexLM5xAtWmBNMeta?.redirect,
    component: () => import("/src/pages/online-exhibitions/index.vue").then(m => m.default || m)
  },
  {
    name: page_45previewqdX5u0GUuHMeta?.name ?? "page-preview",
    path: page_45previewqdX5u0GUuHMeta?.path ?? "/page-preview",
    meta: page_45previewqdX5u0GUuHMeta || {},
    alias: page_45previewqdX5u0GUuHMeta?.alias || [],
    redirect: page_45previewqdX5u0GUuHMeta?.redirect,
    component: () => import("/src/node_modules/@brambergvd/nuxt-base-layer/pages/page-preview.vue").then(m => m.default || m)
  },
  {
    name: request_45for_45photographersjgWGzBuZPWMeta?.name ?? "request-for-photographers___en",
    path: request_45for_45photographersjgWGzBuZPWMeta?.path ?? "/request-for-photographers",
    meta: request_45for_45photographersjgWGzBuZPWMeta || {},
    alias: request_45for_45photographersjgWGzBuZPWMeta?.alias || [],
    redirect: request_45for_45photographersjgWGzBuZPWMeta?.redirect,
    component: () => import("/src/pages/request-for-photographers.vue").then(m => m.default || m)
  },
  {
    name: request_45for_45photographersjgWGzBuZPWMeta?.name ?? "request-for-photographers___al",
    path: request_45for_45photographersjgWGzBuZPWMeta?.path ?? "/al/request-for-photographers",
    meta: request_45for_45photographersjgWGzBuZPWMeta || {},
    alias: request_45for_45photographersjgWGzBuZPWMeta?.alias || [],
    redirect: request_45for_45photographersjgWGzBuZPWMeta?.redirect,
    component: () => import("/src/pages/request-for-photographers.vue").then(m => m.default || m)
  },
  {
    name: talkse8nSpHPxjHMeta?.name ?? "talks___en",
    path: talkse8nSpHPxjHMeta?.path ?? "/talks",
    meta: talkse8nSpHPxjHMeta || {},
    alias: talkse8nSpHPxjHMeta?.alias || [],
    redirect: talkse8nSpHPxjHMeta?.redirect,
    component: () => import("/src/pages/talks.vue").then(m => m.default || m),
    children: [
  {
    name: _91slug_93tZaihNngU0Meta?.name ?? "talks-slug___en",
    path: _91slug_93tZaihNngU0Meta?.path ?? ":slug()",
    meta: _91slug_93tZaihNngU0Meta || {},
    alias: _91slug_93tZaihNngU0Meta?.alias || [],
    redirect: _91slug_93tZaihNngU0Meta?.redirect,
    component: () => import("/src/pages/talks/[slug].vue").then(m => m.default || m)
  }
]
  },
  {
    name: talkse8nSpHPxjHMeta?.name ?? "talks___al",
    path: talkse8nSpHPxjHMeta?.path ?? "/al/talks",
    meta: talkse8nSpHPxjHMeta || {},
    alias: talkse8nSpHPxjHMeta?.alias || [],
    redirect: talkse8nSpHPxjHMeta?.redirect,
    component: () => import("/src/pages/talks.vue").then(m => m.default || m),
    children: [
  {
    name: _91slug_93tZaihNngU0Meta?.name ?? "talks-slug___al",
    path: _91slug_93tZaihNngU0Meta?.path ?? ":slug()",
    meta: _91slug_93tZaihNngU0Meta || {},
    alias: _91slug_93tZaihNngU0Meta?.alias || [],
    redirect: _91slug_93tZaihNngU0Meta?.redirect,
    component: () => import("/src/pages/talks/[slug].vue").then(m => m.default || m)
  }
]
  },
  {
    name: visitqlkmWMbccyMeta?.name ?? "visit___en",
    path: visitqlkmWMbccyMeta?.path ?? "/visit",
    meta: visitqlkmWMbccyMeta || {},
    alias: visitqlkmWMbccyMeta?.alias || [],
    redirect: visitqlkmWMbccyMeta?.redirect,
    component: () => import("/src/pages/visit.vue").then(m => m.default || m)
  },
  {
    name: visitqlkmWMbccyMeta?.name ?? "visit___al",
    path: visitqlkmWMbccyMeta?.path ?? "/al/visit",
    meta: visitqlkmWMbccyMeta || {},
    alias: visitqlkmWMbccyMeta?.alias || [],
    redirect: visitqlkmWMbccyMeta?.redirect,
    component: () => import("/src/pages/visit.vue").then(m => m.default || m)
  }
]