<script setup lang="ts">
const nuxtApp = useNuxtApp()
const { $i18n } = nuxtApp

useHead({
  titleTemplate: (titleChunk) => {
    return titleChunk ? `${titleChunk} - ${$i18n.t('siteTitle')}` : `${$i18n.t('siteTitle')}`
  },
  script: [
    {
      src: 'https://unpkg.com/large-small-dynamic-viewport-units-polyfill@0.0.4/dist/large-small-dynamic-viewport-units-polyfill.min.js'
    }
  ]
})

useSeoMeta({
  viewport: 'user-scalable=no, initial-scale=1, maximum-scale=1, minimum-scale=1, width=device-width, height=device-height, target-densitydpi=device-dpi'
})
</script>

<template>
  <div id="app">
    <NuxtLayout>
      <NavigationLoadingIndicatorComponent />
      <NuxtPage />
    </NuxtLayout>
  </div>
</template>
