import { defineNuxtPlugin } from '#app/nuxt'
import { LazySvgoAADF, LazySvgoArrow, LazySvgoCircle, LazySvgoCross, LazySvgoEducation, LazySvgoHamburger, LazySvgoLogo, LazySvgoMeki, LazySvgoMinus, LazySvgoMoc, LazySvgoPlus, LazySvgoTriangleDown, LazySvgoWoman } from '#components'
const lazyGlobalComponents = [
  ["SvgoAADF", LazySvgoAADF],
["SvgoArrow", LazySvgoArrow],
["SvgoCircle", LazySvgoCircle],
["SvgoCross", LazySvgoCross],
["SvgoEducation", LazySvgoEducation],
["SvgoHamburger", LazySvgoHamburger],
["SvgoLogo", LazySvgoLogo],
["SvgoMeki", LazySvgoMeki],
["SvgoMinus", LazySvgoMinus],
["SvgoMoc", LazySvgoMoc],
["SvgoPlus", LazySvgoPlus],
["SvgoTriangleDown", LazySvgoTriangleDown],
["SvgoWoman", LazySvgoWoman],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
